import '@babel/polyfill';
import './main.scss';
import $ from 'jquery';
// import Swiper from 'swiper/dist/js/swiper';

window.$ = $;
window.jQuery = $;
// window.Swiper = Swiper;
$(function () {
    // 字幕上下交替滚动循环
    let $headerTitles = $('.header-title'),
        $ht1 = $($headerTitles[0]),
        $ht2= $($headerTitles[1]);
    let animateTime = 1000;
    let stayTime = 3000;
    let countTime= animateTime + stayTime;
    +function scrollToTopDown() {
        $ht1.css({transform:'translate3d(0,-380px,0)'});
        setTimeout(function () {
            $ht1.css({transform:'translate3d(0,0,0)'});
            setTimeout(function () {
                $ht2.css({transform:'translate3d(0,-380px,0)'});
                setTimeout(function () {
                    $ht2.css({transform:'translate3d(0,0,0)'});
                    setTimeout(scrollToTopDown,1000);
                },countTime);
            },animateTime);
        },countTime);
    }();

    // 菜单选中
    let path = location.pathname;
    let $menuItems = $('.header .menu-item');
    let isSelect = false;
    $menuItems.each(function () {
        let href = $(this).attr('href');
        console.log(href);
        if(path.indexOf(href)>-1){
            $(this).addClass('active');
            isSelect = true;
        }
    });
    if(!isSelect){
        $($menuItems[0]).addClass('active');
    }
});

let mobile_bs = {
    versions: function () {
        let u = navigator.userAgent;
        return {
            trident: u.indexOf('Trident') > -1, //IE内核
            presto: u.indexOf('Presto') > -1,  //opera内核
            webKit: u.indexOf('AppleWebKit') > -1,  //苹果、谷歌内核
            gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') === -1,  //火狐内核
            mobile: !!u.match(/AppleWebKit.*Mobile.*/) || !!u.match(/AppleWebKit/) && u.indexOf('QIHU') && u.indexOf('QIHU') > -1 && u.indexOf('Chrome') < 0,  //是否为移动终端
            ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/),  //ios终端
            android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1,  //android终端或者uc浏览器
            iPhone: u.indexOf('iPhone') > -1 || u.indexOf('Mac') > -1,   //是否为iPhone或者QQHD浏览器
            iPad: u.indexOf('iPad') > -1,     //是否iPad
            webApp: u.indexOf('Safari') === -1   //是否web应该程序，没有头部与底部
        };
    }()
};

if (mobile_bs.versions.mobile) {
    if (mobile_bs.versions.android || mobile_bs.versions.iPhone || mobile_bs.versions.iPad || mobile_bs.versions.ios) {
        window.location.href = 'm.qiqia.com';
    }
}
